import Sidenav from "../Reusables/SideNav";
import React from "react";
import { Button, TextField, FormControl, Box } from "@mui/material";
import { Logout } from "../Redux/action/User";
import { connect, useDispatch, useSelector } from "react-redux";
import store from "../Store/index";
import { ResourceAdd } from "../Redux/action/User";
import { useEffect, useState, useForm } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { Input, Typography } from "antd";
import { useCallback } from "react";
import { useMemo } from "react";

const AddResource = ({ ResourceAdd }) => {
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "100px",
    margin: "100px",
    borderWidth: 2,
    backgroundColor: "white",
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "double",
    transition: "border .24s ease-in-out",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };
  let formData = new FormData();
  // const [form] = FormControl.useForm();

  const [resourceRef, setResourceRef] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [blogDescription1, setBlogDescription1] = useState("");
  const [blogDescription2, setBlogDescription2] = useState("");
  const [files, setFiles] = useState([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*,video/*",
    maxFiles: "4",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const onAdding = () => {
    formData.append("ResourceRef", resourceRef);
    // console.log("hello");
    formData.append("Title", title);
    // console.log("title", title);
    formData.append("Description", description);
    formData.append("BlogDescription1", blogDescription1);
    formData.append("BlogDescription2", blogDescription2);

    files.forEach((i) => {
      formData.append("Images", i);
    });

    ResourceAdd(formData, (response) => {
      if (response.error) {
        console.log(response.error);
        alert(response.error);
      } else {
        alert("Update Added Successfully");
        setTitle("");
        setDescription("");
        setBlogDescription1("");
        setBlogDescription2("");
        setFiles([]);
      }
    });
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 2">
        <Sidenav />
      </Box>
      <Box
        gridColumn="span 8"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <FormControl
            // {...layout}
            // form={form}
            name="register-form"
            fullWidth
            // onFinish={onAdding}
          >
            <Typography style={{ textAlign: "center", fontSize: "32px" }}>
              Add an Update
            </Typography>
            <TextField
              required
              name="Title"
              label="Title"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              name="Resource Description"
              label="Resource Description (Only For a Resource)"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              onChange={(e) => setDescription(e.target.value)}
              multiline={true}
              //   rules={data.Paragraph1}
            />
            <TextField
              name="Blog Description 1"
              label="Blog Description 1 (Only For a Blog)"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              multiline={true}
              onChange={(e) => setBlogDescription1(e.target.value)}
              //   rules={data.Paragraph1}
            />
            <TextField
              name="Blog Description 2"
              label="Blog Description 2 (Only For a Blog)"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              multiline={true}
              onChange={(e) => setBlogDescription2(e.target.value)}
              //   rules={data.Paragraph1}
            />

            <div
              {...getRootProps(style)}
              style={{
                height: "60%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ textAlign: "center" }}>
                Drag 'n' drop some files here, or click to select files
              </p>
              <section
                className="container"
                style={{
                  width: "50%",
                  borderRadius: "10px",
                  justifySelf: "center",
                  alignSelf: "center",
                }}
              >
                <aside style={thumbsContainer}>{thumbs}</aside>
              </section>
            </div>

            <Button
              style={{
                backgroundColor: "#297CAF",
                color: "white",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={onAdding}
            >
              Add Update
            </Button>
          </FormControl>
        </div>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ResourceAdd: (data, callback) => dispatch(ResourceAdd(data, callback)),
});

export default connect(null, mapDispatchToProps)(AddResource);
