/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import "../../styles/HeaderTwo.css";
import { CSSTransition } from "react-transition-group";
import { Button } from "@mui/material";
import menu from "../../assets/icons8-menu.svg";

export default function HeaderTwo({ ref }) {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="Header" ref={ref}>
      <div className="logoDiv">
        <a href="/">
          <img
            src={require("../../assets/SimplyGifted Logo.png")}
            className="Logo"
            alt="logo"
          />
        </a>
      </div>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <a href="/">HOME</a>

          <a href="/aboutus">ABOUT US</a>

          <a href="/tests">TESTS</a>
          {/* <TestDropdown /> */}

          <a href="/updates">UPDATES</a>

          {/* <a href="/">BLOG</a> */}

          <a href="/contact">CONTACT</a>

          <Button
            variant="contained"
            onClick={() => {
              window.location.replace(
                "https://bookings.simplygifted.us/?view=Grid"
              );
            }}
          >
            Enroll Now
          </Button>
        </nav>
      </CSSTransition>
      <Button
        className="DesktopButton"
        variant="contained"
        style={{
          position: "absolute",
          right: "5%",
          top: "25%",
          backgroundColor: "#FAAF11",
          padding: "0.8rem 2rem",
          display: isNavVisible ? "none" : "block",
          fontSize: "0.8rem",
        }}
        href="https://bookings.simplygifted.us/?view=Grid"
        target="_blank"
        rel="noreferrer"
      >
        Enroll Now
      </Button>
      <button onClick={toggleNav} className="Burger">
        <img alt="menu" src={menu} className="menuIcon" />
      </button>
    </header>
  );
}
