import Sidenav from "../Reusables/SideNav";
import React from "react";
import { Box, Button } from "@mui/material";
import { Logout } from "../Redux/action/User";
import { connect, useDispatch, useSelector } from "react-redux";
import store from "../Store/index";
import Home from "../Components/Home";

const Dashboard = ({ Logout }) => {
  const userRef = store.getState().user.AdminDetails.UserRef;

  const data = { UserRef: userRef };

  const AdminLogout = () => {
    console.log("inside logout");
    Logout(data, (response) => {
      console.log("response", response);
    });
  };
  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 2">
        <Sidenav />
      </Box>
      <Box gridColumn="span 10">
        <Home />
      </Box>
    </Box>  
  );
};
const mapStateToProps = ({ user }) => {
  const { AdminDetails } = user;
  return {
    AdminDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  Logout: (data, callback) => dispatch(Logout(data, callback)),
  //   showLoading: (callback) => dispatch(showLoading(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
