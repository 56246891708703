import API from "../../API/api";
import store from "../../Store/index";
import { ADMINLOGIN } from "../Constants/User";

export const AdminLogin = (data, callback) => (dispatch) => {
  console.log("data", data);
  API.post("user/userlogin", data).then((response) => {
    console.log("response", response);
    if (response.data) {
      localStorage.setItem("AuthToken", response?.data?.Details.AuthToken);
      console.log("response===>>>", response.data);
      dispatch({ type: ADMINLOGIN, payload: response?.data?.Details });
      console.log("response?.data?.Details", response?.data?.Details);
      callback({
        exists: false,
        ItHas: response?.data,
        error: response?.data.Error?.ErrorMessage,
      });
    }
  });
};

export const Logout = (data, callback) => (dispatch) => {
  console.log("userinfo-->", store.getState().user.AdminDetails);
  const token = store.getState().user.AdminDetails.AuthToken;

  console.log("token", token);
  API.post("user/userlogout", data, {
    headers: { AuthToken: token },
  }).then((response) => {
    console.log("signup", response);
  });
};

export const TestAdd = (data, callback) => (dispatch) => {
  data.forEach((element) => {
    console.log("data element", element);
  });
  console.log("userinfo-->", store.getState());
  // const token = store.getState().user.AdminDetails.AuthToken;
  const token = localStorage.getItem("AuthToken");
  console.log("token", token);
  API.post("test/addtest", data, {
    headers: {
      AuthToken: token,
      "Content-type": "multipart/form-data",
    },
  }).then((response) => {
    console.log("testadded", response);
    callback({
      exists: false,
      tests: response?.data.Details,
      ItHas: response?.data,
      error: response?.data.Error?.ErrorMessage,
    });
  });
};

export const ResourceAdd = (data, callback) => (dispatch) => {
  console.log("resource data", data);
  console.log("userinfo-->", store.getState());
  // const token = store.getState().user.AdminDetails.AuthToken;
  const token = localStorage.getItem("AuthToken");
  console.log("token", token);
  API.post("resource/addresource", data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then((response) => {
    console.log("resourceAdded", response);
    callback({
      exists: false,
      tests: response?.data.Details,
      ItHas: response?.data,
      error: response?.data.Error?.ErrorMessage,
    });
  });
};

export const ContentList = (callback) => (dispatch) => {
  // const token = store.getState().user.AdminDetails.AuthToken;
  const token = localStorage.getItem("AuthToken");
  console.log("token", token);
  API.get(`test/fetchtests`, {
    headers: { AuthToken: token },
  }).then((response) => {
    callback({
      exists: false,
      tests: response?.data.Details,
      ItHas: response?.data,
      error: response?.data.Error?.ErrorMessage,
    });
  });
};

export const FetchResources = (callback) => (dispatch) => {
  // const token = store.getState().user.AdminDetails.AuthToken;
  API.get(`resource/fetchresource`).then((response) => {
    console.log(response.data);
    callback({
      exists: false,
      resources: response?.data.Details,
      error: response?.data.Error,
    });
  });
};

export const DeleteResource = (data, callback) => (dispatch) => {
  // const token = store.getState().user.AdminDetails.AuthToken;
  console.log(data);
  API.delete(`resource/deleteresource?ResourceRef=${data.ResourceRef}`).then(
    (response) => {
      console.log(response.data);
      callback({
        exists: false,
        resources: response?.data.Details,
        error: response?.data.Error,
      });
    }
  );
};
