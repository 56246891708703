import Sidenav from "../Reusables/SideNav";
import React from "react";
import { Button, TextField, FormControl, Box, Typography } from "@mui/material";
import { Logout } from "../Redux/action/User";
import { connect, useDispatch, useSelector } from "react-redux";
import store from "../Store/index";
import { TestAdd } from "../Redux/action/User";
import { useEffect, useState, useForm } from "react";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "100px",
  margin: "100px",
  borderWidth: 2,
  backgroundColor: "white",
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  color: "#bdbdbd",
  outline: "double",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 150,
  height: 150,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const AddTest = ({ TestAdd }) => {
  // const [form] = FormControl.useForm();

  const [testRef, settestRef] = useState("");
  const [title, settitle] = useState("");
  const [paragraph1, setparagraph1] = useState("");
  const [paragraph2, setparagraph2] = useState("");
  const [addedby, setaddedby] = useState("");
  const [files, setFiles] = useState([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    maxFiles: "3",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const onAdding = () => {
    let formData = new FormData();
    // formData.append("TestRef", testRef);
    // console.log("hello");
    formData.append("Title", title);
    // console.log("title", title);
    formData.append("Paragraph1", paragraph1);
    formData.append("Paragraph2", paragraph2);

    files.forEach((i) => {
      formData.append("Images", i);
    });

    // formData.append("Images", files);
    formData.append("AddedBy", addedby);

    TestAdd(formData, (response) => {
      console.log("testAdssss", response);
      if (response.error) {
        console.log(response.error);
        alert(response.error);
      } else {
        alert("Test Added Successfully");
        settitle("");
        setparagraph1("");
        setparagraph2("");
        setaddedby("");
        setFiles([]);
      }
    });
  };

  //  form
  //     .validateFields()
  //     .then((values) => {}

  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 2">
        <Sidenav />
      </Box>
      <Box
        gridColumn="span 8"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <FormControl
            // {...layout}
            // form={form}
            // layout="vertical"
            name="register-form"
            fullWidth
            // onFinish={onAdding}
          >
            <Typography style={{ textAlign: "center", fontSize: "32px" }}>
              Add a Test
            </Typography>
            <TextField
              required
              id="outlined-basic"
              label="Title"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
            <TextField
              required
              id="outlined-basic"
              label="Paragraph1"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              value={paragraph1}
              multiline={true}
              onChange={(e) => setparagraph1(e.target.value)}
              //   rules={data.Paragraph1}
            />
            <TextField
              required
              id="outlined-basic"
              label="Paragraph2"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              value={paragraph2}
              multiline={true}
              onChange={(e) => setparagraph2(e.target.value)}
              //   rules={data.Paragraph2}
            />

            <TextField
              required
              id="outlined-basic"
              label="Added By"
              style={{
                marginBottom: "10px",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
              }}
              value={addedby}
              onChange={(e) => setaddedby(e.target.value)}
              //   rules={data.Paragraph2}
            />

            {/* <input type="file" onChange={handleChange} /> */}
            <div
              {...getRootProps(style)}
              style={{
                height: "60%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ textAlign: "center" }}>
                Drag 'n' drop some files here, or click to select files
              </p>
              <section
                className="container"
                style={{
                  width: "50%",
                  borderRadius: "10px",
                  justifySelf: "center",
                  alignSelf: "center",
                }}
              >
                <aside style={thumbsContainer}>{thumbs}</aside>
              </section>
            </div>

            {/* <input
            accept="image/*"
            
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
          /> */}
            {/* <div>
            Files :
            {imageupload.map((ImageUrl1) => (
              <div>{ImageUrl1.path} </div>
            ))}
          </div> */}

            <Button
              style={{
                backgroundColor: "#297CAF",
                color: "white",
                width: "50%",
                justifySelf: "center",
                alignSelf: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={onAdding}
            >
              Add Test
            </Button>
          </FormControl>
        </div>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  TestAdd: (data, callback) => dispatch(TestAdd(data, callback)),
  //   showLoading: (callback) => dispatch(showLoading(callback)),
});

export default connect(null, mapDispatchToProps)(AddTest);
