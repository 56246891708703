import React from "react";
import SingleBlog from "../Components/SingleBlog";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";

const Resource = () => {
  return (
    <>
      <HeaderTwo />
      <SingleBlog />
      <Footer />
    </>
  );
};

export default Resource;
