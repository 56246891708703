import React, { useState } from "react";
// import Image from "../assets/WelcomeToSG.png";
import Image from "../assets/kids.jpg";
import Button from "@mui/material/Button";
import "../styles/WelcomeToSG.css";
import { useEffect } from "react";

// import {Button} from 'antd'

const WelcomeToSG = ({ from }) => {
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    window.innerWidth > 600 ? setViewMore(true) : setViewMore(false);
  }, []);

  return (
    <div className="containerSG">
      <div className="Font1">
        <h1 className="welcomeHeader">Welcome to SimplyGifted</h1>
      </div>

      <div className="content">
        {from === "home" && (
          <img src={Image} alt="Welcome" className="welcomeImg" />
        )}
        <div className="Font1">
          <p>
            "Gifted" or "Talented" children are those who demonstrate
            outstanding levels of aptitude (exceptional ability to reason and
            learn) or competence in one or more domains.
          </p>

          {viewMore && (
            <>
              <p>
                For these talented children to gain access to gifted education
                programs in the US, they are required to qualify through one of
                the multiple gifted program tests offered by various
                organizations.
              </p>
              <p>
                We at SimplyGifted tutor children between Kindergarten through
                8th Grade to prepare for these tests, including CogAT, ITBS and
                NNAT among others. With the increasing popularity of Gifted and
                Talented programs across the country, we aim to offer a valuable
                learning experience and help prepare your child for these
                entrance tests through a combination of one-to-one tutoring by
                experienced teachers, covering key concepts and sample test
                content to practice for each test.
              </p>
              {from === "home" && (
                <div className="knowMoreDiv">
                  <a className="knowMore" href="/aboutus">
                    Know More
                  </a>
                </div>
              )}
            </>
          )}
          <button
            className="viewMoreBtn"
            onClick={() => {
              setViewMore(!viewMore);
            }}
          >
            {viewMore ? "View Less" : "View More"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeToSG;
