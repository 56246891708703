import React, { useEffect, useState } from "react";
import LineBottom from "../assets/LineBottom.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Divider from "@mui/material/Divider";
import CardImage from "../assets/CardImage.png";
import CogAT from "../assets/CogAT.png";
import NNAT from "../assets/NNAT.png";
import ITBS from "../assets/ITBS.png";
import OLSAT from "../assets/OLSAT.png";
import SCAT from "../assets/SCAT.png";
// import Carousel from "framer-motion-carousel";
import Carousel from "react-material-ui-carousel";
import "../styles/PopularTests.css";
import axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const OurPopularTests = ({ from }) => {
  const [tests, setTests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://simplygiftedbackend.com/api/test/fetchtests",
        {
          headers: {
            Authorization: "NGlhQVNVWEc2czpoS1dna3d1SW9v",
            AppVersion: "1.0.0",
          },
        }
      );
      console.log(response.data.Details);
      setTests(response.data.Details);
    };
    fetchData();
  }, []);

  // const isDesktopResolution = useMatchMedia("(min-width:1200px)", true);
  return (
    <div className="popularTestsMainDiv">
      {/* <img
        src={LineBottom}
        style={{ width: "100vw", position: "relative", top: -5 }}
      /> */}
      <div>
        <div className="Font1">
          <h1 className="popularHeading">Tests Offered</h1>
        </div>
        <div className="Font2">
          <p>Get to know more on the various test programs we have to offer.</p>
        </div>
      </div>
      {from === "home" && (
        <div>
          <div className="tests">
            <div>
              <Card sx={{ maxWidth: 345, height: "100%" }}>
                <CardActionArea href="/tests/5a170320-575e-11ed-ab7c-0a523866bb5a">
                  <CardMedia
                    component="img"
                    height="100%"
                    image={CogAT}
                    alt="CogAT"
                  />
                  {/* <img src={CardImage} /> */}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="#FAAF11"
                      fontFamily="Poppins"
                      fontSize="1rem"
                    >
                      The Cognitive Abilities test
                    </Typography>
                    <Divider style={{ width: "30%" }} />
                    <Typography
                      variant="p"
                      color="text.secondary"
                      fontFamily="Poppins"
                      fontSize="0.7rem"
                    >
                      Know More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card sx={{ maxWidth: 345, height: "100%" }}>
                <CardActionArea href="/tests/c7fea205-575e-11ed-ab7c-0a523866bb5a">
                  <CardMedia
                    component="img"
                    height="100%"
                    // image={CardImage}
                    image={ITBS}
                    alt="ITBS"
                  />{" "}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="#00A3E3"
                      fontFamily="Poppins"
                      fontSize="1rem"
                    >
                      The Iowa Test of Basic Skills / The Iowa Assessments
                    </Typography>
                    <Divider style={{ width: "30%" }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontFamily="Poppins"
                      fontSize="0.7rem"
                    >
                      Know More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card sx={{ maxWidth: 345, height: "100%" }}>
                <CardActionArea href="/tests/521b63f7-575f-11ed-ab7c-0a523866bb5a">
                  <CardMedia
                    component="img"
                    height="100%"
                    // image={CardImage}
                    image={NNAT}
                    alt="NNAT"
                  />{" "}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="#FAAF11"
                      fontFamily="Poppins"
                      fontSize="1rem"
                    >
                      The Naglieri Nonverbal Ability Test
                    </Typography>
                    <Divider />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontFamily="Poppins"
                      fontSize="0.7rem"
                    >
                      Know More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card sx={{ maxWidth: 345, height: "100%" }}>
                <CardActionArea href="/tests/9668ed73-575f-11ed-ab7c-0a523866bb5a">
                  <CardMedia
                    component="img"
                    height="100%"
                    // image={CardImage}
                    image={OLSAT}
                    alt="OLSAT"
                  />{" "}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="#00A3E3"
                      fontFamily="Poppins"
                      fontSize="1rem"
                    >
                      The Otis-Lennon School Ability Test
                    </Typography>
                    <Divider style={{ width: "30%" }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontFamily="Poppins"
                      fontSize="0.7rem"
                    >
                      Know More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card sx={{ maxWidth: 345, height: "100%" }}>
                <CardActionArea href="/tests/29aa98fc-5760-11ed-ab7c-0a523866bb5a">
                  <CardMedia
                    component="img"
                    height="100%"
                    // image={CardImage}
                    image={SCAT}
                    alt="SCAT"
                  />{" "}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color="#FAAF11"
                      fontFamily="Poppins"
                      fontSize="1rem"
                    >
                      The School and College Abilities Test
                    </Typography>
                    <Divider style={{ width: "30%" }} />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontFamily="Poppins"
                      fontSize="0.7rem"
                    >
                      Know More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card
                sx={{
                  maxWidth: 345,
                  height: "100%",
                  backgroundColor: "#FAAF11",
                }}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="white"
                      fontFamily="Poppins"
                    >
                      More tests Coming Soon...
                    </Typography>
                    <Divider />
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
          <div className="allTestButton">
            <a href="/tests">VIEW ALL TESTS</a>
          </div>
        </div>
      )}

      {from === "home" && (
        <>
          <div className="carousel">
            <Carousel indicators={false} navButtonsAlwaysVisible={true}>
              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href="/tests/5a170320-575e-11ed-ab7c-0a523866bb5a">
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={CogAT}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="#FAAF11"
                        fontSize="1rem"
                        fontFamily="Poppins"
                      >
                        The Cognitive Abilities Test
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href="/tests/c7fea205-575e-11ed-ab7c-0a523866bb5a">
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={ITBS}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="#00A3E3"
                        fontSize="1rem"
                        fontFamily="Poppins"
                      >
                        The Iowa Test of Basic Skills / The Iowa Assessments
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href="/tests/521b63f7-575f-11ed-ab7c-0a523866bb5a">
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={NNAT}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="#FAAF11"
                        fontSize="1rem"
                        fontFamily="Poppins"
                      >
                        The Naglieri Nonverbal Ability Test
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href="/tests/9668ed73-575f-11ed-ab7c-0a523866bb5a">
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={OLSAT}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="#00A3E3"
                        fontSize="1rem"
                        fontFamily="Poppins"
                      >
                        The Otis-Lennon School Ability Test
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href="/tests/29aa98fc-5760-11ed-ab7c-0a523866bb5a">
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={SCAT}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontSize="1rem"
                        color="#FAAF11"
                        fontFamily="Poppins"
                      >
                        The School and College Abilities Test
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </Carousel>
          </div>
          <div className="allTestButtonMobile">
            <a href="/tests">VIEW ALL TESTS</a>
          </div>
        </>
      )}

      {from === "tests" && (
        <div>
          <div className="tests">
            {tests?.map((test) => (
              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href={`/tests/${test.TestRef}`}>
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={test.ImageUrl1}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="#00A3E3"
                        fontSize="1rem"
                        fontFamily="Poppins"
                      >
                        {test.Title}
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}

      {from === "tests" && (
        <div className="carousel">
          <Carousel indicators={false} navButtonsAlwaysVisible={true}>
            {tests?.map((test) => (
              <div>
                <Card sx={{ maxWidth: 600, height: "100%" }}>
                  <CardActionArea href={`/tests/${test.TestRef}`}>
                    <CardMedia
                      component="img"
                      height="100%"
                      // image={CardImage}
                      image={test.ImageUrl1}
                      alt="Test"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="#FAAF11"
                        fontSize="1rem"
                        fontFamily="Poppins"
                      >
                        {test.Title}
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        fontSize="0.7rem"
                        fontFamily="Poppins"
                      >
                        Know More
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default OurPopularTests;
