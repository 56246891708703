import React from "react";
import "../styles/Resources.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "../Components/Pagination";
import { Divider } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Resource = () => {
  const [resources, setResource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://simplygiftedbackend.com/api/resource/fetchresource",
        {
          headers: {
            Authorization: "NGlhQVNVWEc2czpoS1dna3d1SW9v",
            AppVersion: "1.0.0",
          },
        }
      );
      console.log(response.data.Details);
      console.log(
        "media",
        response.data.Details[0].Image1Url.split("ImageUploads/")[1].split(
          "."
        )[1]
      );
      setResource(response.data.Details);
    };
    fetchData();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = resources.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="resourcesBody">
      <div>
        <h1 className="resourcesHeading">Updates</h1>
        <h4 className="resourcesSubHeading">
          A collection of blogs, videos and images related to SimplyGifted and
          gifted testing.
        </h4>
      </div>
      <div className="singleResource">
        <div className="resourceDescription">
          {currentPosts.map((resource) => (
            <div className="allResources">
              {resource.Image1Url?.split("ImageUploads/")[1]?.split(".")[1] ===
              "mp4" ? (
                <video className="popularSingleResourceVideo" controls>
                  <source src={resource.Image1Url} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={resource.Image1Url}
                  className="popularSingleResourceImage"
                  alt="resource"
                />
              )}
              <div className="resourceDetails">
                <h1>{resource.Title}</h1>

                <p>
                  {resource.Description
                    ? resource.Description
                    : resource.BlogDescription1}
                </p>

                <div className="resourceDate">
                  <p>
                    {moment(resource.CreatedOn.split(" ")[0]).format(
                      "MMMM Do, YYYY"
                    )}
                  </p>
                </div>
                {resource.BlogDescription1 && (
                  <button
                    className="resourcesReadMoreBtn"
                    onClick={() => {
                      navigate(`/updates/${resource.ResourceRef}`, {
                        state: { resources },
                      });
                    }}
                  >
                    Read More
                  </button>
                )}
              </div>
            </div>
          ))}
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={resources.length}
            paginate={paginate}
          />
        </div>
        <div className="popularResourceTab">
          <h2>Latest Updates</h2>
          <div className="popularResource">
            {resources.map((resource, index) => {
              if (index < 5) {
                return (
                  <div className="popularResourcesDiv">
                    <div className="popularResourceMedia">
                      {resource.Image1Url?.split("ImageUploads/")[1]?.split(
                        "."
                      )[1] === "mp4" ? (
                        <video className="popularResourceDivVideo" controls>
                          <source src={resource.Image1Url} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={resource.Image1Url}
                          className="popularResourceDivImage"
                          alt="resource"
                        />
                      )}
                      {/* <img
                      src={resource.ImageUrl}
                      className="popularSingleTestImage"
                      alt="resource"
                    /> */}
                    </div>
                    <div className="popularSingleTestDivText">
                      <h3>{resource.Title}</h3>
                      <Divider style={{ backgroundColor: "#faaf11" }} />
                      <p>
                        {moment(resource.CreatedOn.split(" ")[0]).format(
                          "MMMM Do, YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resource;
