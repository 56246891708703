import React from "react";
import "../styles/HowItWorks.css";
import KidImageRight from "../assets/HowItWorksKidRight.png";
import KidImageLeft from "../assets/HowItWorksKidLeft.png";
import How1 from "../assets/How(1).png";
import How2 from "../assets/How(2).png";
import How3 from "../assets/How(3).png";
import How4 from "../assets/How(4).png";
import How5 from "../assets/How(5).png";
import How6 from "../assets/How(6).png";

import One from "../assets/One.png";
import Two from "../assets/Two.png";
import Three from "../assets/Three.png";
import Four from "../assets/Four.png";
import Five from "../assets/Five.png";
import Six from "../assets/Six.png";
import Sun from "../assets/illustrations/sun.png";
import Book from "../assets/illustrations/book.png";
import Rocket from "../assets/illustrations/rocket.png";
import Earth from "../assets/illustrations/earth.png";
import PaintBrush from "../assets/illustrations/paintBrush.png";
import PaperPlane from "../assets/illustrations/paperPlane.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

const HowItWorks = ({ from }) => {
  return (
    <div className="hiwbody">
      {from === "home" && (
        <>
          <img src={Sun} className="sun" />
          <img src={Book} className="book" />
          <img src={Rocket} className="rocket" />
          <img src={Earth} className="earth" />
          <img src={PaintBrush} className="paintBrush" />
          <img src={PaperPlane} className="paperPlane" />
        </>
      )}
      <div className="headerText">
        <h1>Here's how it works</h1>
        <p>
          With a sound focus on your child's preparations for gifted programs,
          we look towards delivering a valuable experience of exceptional,
          functional, and affordable one-to-one tutoring.
        </p>
      </div>

      {from === "home" && (
        <>
          <div className="detailsRev">
            <div className="detail ">
              <img src={One} alt="One" className="number" />
              <h2 className="detailsHeader">Schedule a call with us</h2>
              <p className="textLeft">
                Share the test goals for your child with us. We will discuss
                these requirements with our team and prepare an apt study plan
                for your child.
              </p>
              {/* <a className="detailsCTA">LEARN MORE</a> */}
            </div>
            <div>
              <img src={KidImageLeft} className="imageKid" alt="shoutout" />
            </div>
          </div>
          <div className="details">
            <div className="detail ">
              <img src={Two} alt="Two" className="number" />
              <h2 className="detailsHeader">Sign Up to SimplyGifted</h2>
              <p className="textRight">
                With a customized study plan waiting for your child, sign on to
                our program to gain access to the material and begin your
                child's test preparation with SimplyGifted right away!
              </p>
              {/* <a className="detailsCTA">LEARN MORE</a> */}
            </div>
            <div>
              <img src={How4} className="imageKid" alt="chart" />
            </div>
          </div>
          <div className="detailsRev">
            <div className="detail ">
              <img src={Three} alt="Three" className="number" />
              <h2 className="detailsHeader">
                Schedule a class with your Tutor
              </h2>
              <p className="textLeft">
                Our tutors will work towards building key concepts and cover
                different question types to strengthen your child’s basic
                understanding with every class.
              </p>
              {/* <a className="detailsCTA">LEARN MORE</a> */}
            </div>
            <div>
              <img src={How1} className="imageKid" alt="Mountain" />
            </div>
          </div>
          <div className="details">
            <div className="detail ">
              <img src={Four} alt="Four" className="number" />
              <h2 className="detailsHeader">
                Interact with the Tutor through personalized learning
              </h2>
              <p className="textRight">
                Our tutors are not only knowledgeable but have the exposure of
                working with children in the past, so your child is bound to get
                outstanding tutoring and an enjoyable learning experience.
              </p>
              {/* <a className="detailsCTA">LEARN MORE</a> */}
            </div>
            <div>
              <img src={How5} className="imageKid" alt="product" />
            </div>
          </div>
          <div className="detailsRev">
            <div className="detail ">
              <img src={Five} alt="Five" className="number" />
              <h2 className="detailsHeader">
                Get feedback and work on your scores
              </h2>
              <p className="textLeft">
                Our one-on-one learning approach enables a deeper understanding
                of your child’s strengths & weaknesses, which the tutor focuses
                on to get the best out of him/her, provide feedback, and make
                room for improvement.
              </p>
              {/* <a className="detailsCTA">LEARN MORE</a> */}
            </div>
            <div>
              <img src={How3} className="imageKid" alt="Mountain" />
            </div>
          </div>
          <div className="details">
            <div className="detail ">
              <img src={Six} alt="six" className="number" />
              <h2 className="detailsHeader">
                Solve doubts & learn through practice
              </h2>
              <p className="textRight">
                Since practicing is key to any preparation, we ensure to
                accomplish it by providing ample test prep content. Our tutors
                will then help your child in solving doubts.
              </p>
              {/* <a className="detailsCTA">LEARN MORE</a> */}
            </div>
            <div>
              <img src={How6} className="imageKid" alt="product" />
            </div>
          </div>{" "}
        </>
      )}
      {from === "about" && (
        <div className="programCards">
          <div>
            <Card sx={{ maxWidth: 345, height: "100%" }}>
              <CardMedia
                component="img"
                height="100"
                image={One}
                alt="One"
                style={{
                  objectFit: "contain",
                  padding: "1rem",
                  marginTop: "3rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  color="#FAAF11"
                  fontFamily="Fredoka One"
                  fontSize="1.7rem"
                  textAlign="center"
                >
                  Schedule a call with us
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  fontFamily="Poppins"
                  fontSize="0.8rem"
                  textAlign="center"
                >
                  Share the test goals for your child with us. We will discuss
                  these requirements with our team and prepare an apt study plan
                  for your child.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card sx={{ maxWidth: 345, height: "100%" }}>
              <CardMedia
                component="img"
                height="100"
                image={Two}
                alt="two"
                style={{
                  objectFit: "contain",
                  padding: "1rem",
                  marginTop: "3rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  color="#FAAF11"
                  fontFamily="Fredoka One"
                  fontSize="1.7rem"
                  textAlign="center"
                >
                  Sign Up to SimplyGifted
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  fontFamily="Poppins"
                  fontSize="0.8rem"
                  textAlign="center"
                >
                  With a customized study plan waiting for your child, sign on
                  to our program to gain access to the material and begin your
                  child's test preparation with SimplyGifted right away!
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card sx={{ maxWidth: 345, height: "100%" }}>
              <CardMedia
                component="img"
                height="100"
                image={Three}
                alt="three"
                style={{
                  objectFit: "contain",
                  padding: "1rem",
                  marginTop: "3rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  color="#FAAF11"
                  fontFamily="Fredoka One"
                  fontSize="1.7rem"
                  textAlign="center"
                >
                  Schedule a class with your Tutor
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  fontFamily="Poppins"
                  fontSize="0.8rem"
                  textAlign="center"
                >
                  Our tutors will work towards building key concepts and cover
                  different question types to strengthen your child’s basic
                  understanding with every class.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card sx={{ maxWidth: 345, height: "100%" }}>
              <CardMedia
                component="img"
                height="100"
                image={Four}
                alt="four"
                style={{
                  objectFit: "contain",
                  padding: "1rem",
                  marginTop: "3rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  color="#FAAF11"
                  fontFamily="Fredoka One"
                  fontSize="1.7rem"
                  textAlign="center"
                >
                  Interact with the Tutor through personalized learning
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  fontFamily="Poppins"
                  fontSize="0.8rem"
                  textAlign="center"
                >
                  Our tutors are not only knowledgeable but have the exposure of
                  working with children in the past, so your child is bound to
                  get outstanding tutoring and an enjoyable learning experience.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card sx={{ maxWidth: 345, height: "100%" }}>
              <CardMedia
                component="img"
                height="100"
                image={Five}
                alt="five"
                style={{
                  objectFit: "contain",
                  padding: "1rem",
                  marginTop: "3rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  color="#FAAF11"
                  fontFamily="Fredoka One"
                  fontSize="1.7rem"
                  textAlign="center"
                >
                  Get feedback and work on your scores
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  fontFamily="Poppins"
                  fontSize="0.8rem"
                  textAlign="center"
                >
                  Our one-on-one learning approach enables a deeper
                  understanding of your child’s strengths & weaknesses, which
                  the tutor focuses on to get the best out of him/her, provide
                  feedback, and make room for improvement.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card sx={{ maxWidth: 345, height: "100%" }}>
              <CardMedia
                component="img"
                height="100"
                image={Six}
                alt="six"
                style={{
                  objectFit: "contain",
                  padding: "1rem",
                  marginTop: "3rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  color="#FAAF11"
                  fontFamily="Fredoka One"
                  fontSize="1.7rem"
                  textAlign="center"
                >
                  Solve doubts & learn through practice
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  fontFamily="Poppins"
                  fontSize="0.8rem"
                  textAlign="center"
                >
                  Since practicing is key to any preparation, we ensure to
                  accomplish it by providing ample test prep content. Our tutors
                  will then help your child in solving doubts.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default HowItWorks;
