import React from "react";
import "../styles/Resources.css";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import ResourceBanner from "../Components/ResourceBanner";
import Footer from "../Components/Reusable/Footer";
import Resource from "../Components/Resource";

const Resources = () => {
  return (
    <>
      <HeaderTwo />
      <Resource />
      <Footer />
    </>
  );
};

export default Resources;
