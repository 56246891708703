import React from "react";
import "../styles/SingleTest.css";
import CogAT from "../assets/CogAT.png";
import ITBS from "../assets/ITBS.png";
import NNAT from "../assets/NNAT.png";
import OLSAT from "../assets/OLSAT.png";
import SCAT from "../assets/SCAT.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const SingleTest = () => {
  const [test, setTest] = useState({});

  let location = useLocation();
  let testRef = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://simplygiftedbackend.com/api/test/fetchtests?TitleRef=${testRef}`,
        {
          headers: {
            Authorization: "NGlhQVNVWEc2czpoS1dna3d1SW9v",
            AppVersion: "1.0.0",
          },
        }
      );
      setTest(response.data.Details[0]);
    };
    fetchData();
  }, []);

  return (
    <div className="singleTest">
      <div className="testDescription">
        <h1>{test.Title}</h1>
        <p>{test.Paragraph1}</p>
        {/* <img src={test.ImageUrl1} alt="test" /> */}
        <h3>What does this test contain?</h3>
        {/* <p>{test.Paragraph3}</p> */}
        <p>{test.Paragraph2}</p>
        <div className="testContain">
          <img src={test.ImageUrl2} alt="test" />
        </div>
        <div className="kickOff">
          <a href="/contact">
            Kick off with {test.Title} preparations now by clicking here
          </a>
        </div>
        <div className="tradeMark">
          <p>
            {test.Title} and other trademarks are the property of their
            respective trademark holders. None of the trademark holders are
            affiliated with SimplyGifted or this website.
          </p>
        </div>
      </div>
      <div className="popularTestsTab">
        <h2>Popular Tests</h2>
        <a href="/tests/5a170320-575e-11ed-ab7c-0a523866bb5a">
          <div className="popularSingleTest">
            <div className="popularSingleTestDiv">
              <div className="popularSingleTestDivImage">
                <img src={CogAT} className="popularSingleTestImage" />
              </div>
              <div className="popularSingleTestDivText">
                <h3>CogAT</h3>
                <p>Cognitive Abilities Test</p>
              </div>
            </div>
          </div>
        </a>
        <a href="/tests/c7fea205-575e-11ed-ab7c-0a523866bb5a">
          <div className="popularSingleTest">
            <div className="popularSingleTestDiv">
              <div className="popularSingleTestDivImage">
                <img src={ITBS} className="popularSingleTestImage" />
              </div>
              <div className="popularSingleTestDivText">
                <h3>ITBS</h3>
                <p>Iowa Test of Basic Skills / Iowa Assessments</p>
              </div>
            </div>
          </div>
        </a>
        <a href="/tests/521b63f7-575f-11ed-ab7c-0a523866bb5a">
          <div className="popularSingleTest">
            <div className="popularSingleTestDiv">
              <div className="popularSingleTestDivImage">
                <img src={NNAT} className="popularSingleTestImage" />
              </div>
              <div className="popularSingleTestDivText">
                <h3>NNAT</h3>
                <p>Naglieri Nonverbal Abilities Test</p>
              </div>
            </div>
          </div>
        </a>
        <a href="/tests/9668ed73-575f-11ed-ab7c-0a523866bb5a">
          <div className="popularSingleTest">
            <div className="popularSingleTestDiv">
              <div className="popularSingleTestDivImage">
                <img src={OLSAT} className="popularSingleTestImage" />
              </div>
              <div className="popularSingleTestDivText">
                <h3>OLSAT</h3>
                <p>Otis-Lennon School Ability Test</p>
              </div>
            </div>
          </div>
        </a>
        <a href="/tests/29aa98fc-5760-11ed-ab7c-0a523866bb5a">
          <div className="popularSingleTest">
            <div className="popularSingleTestDiv">
              <div className="popularSingleTestDivImage">
                <img src={SCAT} className="popularSingleTestImage" />
              </div>
              <div className="popularSingleTestDivText">
                <h3>SCAT</h3>
                <p>School and College Ability Test</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default SingleTest;
