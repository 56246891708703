import { combineReducers } from "redux";
// import Auth from "./Auth";
// import category from "./Categories";
// import Theme from "./Theme";
import user from "./User";

const reducers = combineReducers({
  //   theme: Theme,
  //   auth: Auth,
  user: user,
  //   category: category,
});

export default reducers;
