import React from "react";

// import LogIn from "./login";
// import LogIn from "./login";
import { Router, Switch, Route, BrowserRouter, Routes } from "react-router-dom";
import Sidenav from "../Reusables/SideNav";
import TestList from "./list";

const Lists = () => {
  return (
    <>
      <TestList />
    </>
  );
};

export default Lists;
