import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import { AdminLogin } from "../Redux/action/User";
import Alert from "@mui/material/Alert";
import { connect, useDispatch, useSelector } from "react-redux";

import "./login.css";

import { useNavigate } from "react-router-dom";
const LogIn = ({ AdminLogin }) => {
  const { useState } = React;

  const navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const [inpass, setinpass] = useState("password");
  const [warning, setwarning] = useState(false);
  const [tick, settick] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState(false);

  const [wemail, setwemail] = useState(false);
  const [wpassword, setwpassword] = useState(false);

  const Eye = () => {
    const data = {
      Username: email,
      Password: password,
    };

    console.log("data", data);

    AdminLogin(data, (response) => {
      console.log("response", response);
      if (!response.exists) {
        navigate("/Dashboard");
        console.log("response==>", response);
        if (response.error) {
          seterror(true);
        } else {
          seterror(false);
        }
      }
    });
  };

  return (
    <>
      <div className="container">
        <div className="card">
          <div className="form">
            <div className="left-side">
              <img src="https://imgur.com/K230JeW.jpg" />
            </div>
            <div className="right-side">
              <div className="heading">
                <h3>
                  <center>Log in </center>
                </h3>
                <p>
                  Welcome Back! login with your data that you entered during
                  registration.
                </p>
              </div>

              <hr />
              {/* <div className="or">
                <p>or</p>
              </div> */}
              {error && (
                <Alert severity="error">Invalid User Credentials</Alert>
              )}

              <form>
                <div className="input-text">
                  <input
                    type="text"
                    className={`${wemail ? "text-warning" : ""}`}
                    // value={inputText.email}
                    // onChange={inputEvent}
                    onChange={(e) => setemail(e.target.value)}
                    name="email"
                  />
                  <label>Email</label>
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="input-text">
                  <input
                    type={inpass}
                    className={` ${warning ? "warning" : ""} ${
                      wpassword ? "text-warning" : ""
                    }`}
                    onChange={(e) => setpassword(e.target.value)}
                    // value={inputText.password}
                    // onChange={inputEvent}
                    name="password"
                  />
                  <label>Password</label>
                  <i className="fa fa-lock"></i>
                  <i
                    onClick={Eye}
                    className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                  ></i>
                </div>

                <div className="button">
                  <Button
                    onClick={() => {
                      Eye();
                    }}
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  const { AdminDetails } = user;
  return {
    AdminDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  AdminLogin: (data, callback) => dispatch(AdminLogin(data, callback)),
  //   showLoading: (callback) => dispatch(showLoading(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
