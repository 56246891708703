import React from "react";
import WEDoSomethingBackground from "../assets/WeDoSomething.png";
import "../styles/WeDoSomething.css";

const WEDoSomething = () => {
  return (
    <div className="containerWeDoSomething">
      <h1>Personalized . Affordable . Simple</h1>
      <p>Personalized.</p>
      <p>Affordable.</p>
      <p>Simple.</p>
      <a href="/contact">
        GET ACCESS TO THE <br className="mobileLine" />
        CUSTOMIZED PLAN HERE!
      </a>
    </div>
  );
};

export default WEDoSomething;
