import "../../styles/Footer.css";

import linkedIn from "../../assets/linkedIn.svg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/Instagram icon.png";
import facebook from "../../assets/facebook.svg";
import youtube from "../../assets/Youtube.png";

import topArrow from "../../assets/topArrow.svg";
import logo from "../../assets/SimplyGifted Footer Logo.png";
import mail from "../../assets/mail.png";
import mapPin from "../../assets/mapPin.png";
import phone from "../../assets/phone.png";

const Footer = () => {
  return (
    <div className="body">
      <div className="content">
        <div className="subscribe">
          {/* <h3>SIMPLYGIFTED</h3> */}
          <img src={logo} alt="logo" className="footerLogo" />
        </div>
        <div className="linksMain">
          <div className="linksCol">
            <h3>INFORMATION</h3>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/aboutus">About Us</a>
              </li>
              <li>
                <a href="/tests">Tests</a>
              </li>
              <li>
                <a href="/updates">Updates</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="linksCol linksContact">
            <h3>CONTACT US</h3>
            <ul>
              <li>
                <div className="mail">
                  <img src={mail} alt="mail" className="mailIcon" />
                  <a href="mailto:outreach@simplygifted.us">
                    outreach@simplygifted.us
                  </a>
                </div>
              </li>
              <li>
                <div className="phone">
                  <img src={phone} alt="phone" className="phoneIcon" />
                  <a href="tel:(844) 690-2124">(844) 690-2124</a>
                </div>
              </li>
              <li>
                <div className="address">
                  <img src={mapPin} alt="address" className="addressIcon" />
                  <div>
                    <p>3075 Book Road, Ste 103</p>
                    <p>PO Box 9162</p>
                    <p>Naperville, IL 60567-9162</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="privacyPo">
                  <a href="/tnc">T&C</a>
                  <a href="/privacy-policy">Privacy Policy</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr style={{ opacity: 0.2, marginTop: "2.5rem" }} />
      <div className="copyright">
        <p>
          &copy; {new Date().getFullYear()} SIMPLYGIFTED, All Rights Reserved
        </p>
        <div className="socialMedia">
          <a
            href="https://www.facebook.com/simplygifted.us/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/simplygifted/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedIn} alt="linkedIn" />
          </a>
          {/* <a
            href="https://twitter.com/simplygiftedus"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a> */}
          <a
            href="https://www.youtube.com/channel/UC6RcQBVEatPwQDzbYkBBl7w"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" />
          </a>
          <a
            href="https://www.instagram.com/simplygifted.us/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </div>
      </div>
      {/* <div
        className="topArrow"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <img src={topArrow} alt="topArrow" />
      </div> */}
    </div>
  );
};

export default Footer;
