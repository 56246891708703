import React from "react";
import "../styles/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacyBody">
      <h1>Privacy Policy</h1>
      <h2>Consent</h2>
      <p>
        Please read this privacy policy (“Policy“) carefully before using the
        Website, its services and products, along with the Terms of Use (“ToU“)
        provided on the Website. Your use of the Website, or services in
        connection Website or products (“Services“), or registrations with us
        through any mode or use of any products and services SimplyGifted
        (hereinafter referred to as “SimplyGifted” or “Company” or “We” or “Our”
        or “Us”), a brand of Global Guru LLC Naperville, IL. www.simplygifted.us
        is operated by SimplyGifted. SimplyGifted is a platform to help children
        learn Indian vernacular languages. The Company enables the enrolment of
        the children on the website of the Company viz. www.simplygifted.us (the
        “Website”) The Company is committed to protect the customers’ privacy
        and ensure you that they have a positive experience on the Website.
        SimplyGifted can be reached, for all questions and directions related to
        this privacy policy (the “Privacy Policy”), by: This Privacy Policy
        (‘‘Privacy Policy’’) is meant to help you understand what information we
        collect, why we collect it, and how you can update, manage, export, and
        delete your information and shall be read with the Terms and Conditions
        found on (URL: https://www.simplygifted.us/tnc ). We provide online
        courses for different languages to children on the Platform (hereinafter
        referred to as “Services”). We reserve the right to amend and supplement
        to complete this Privacy Policy at any time. We encourage you to
        regularly review this Privacy Policy to get the latest updates to ensure
        you know and exercise the right to manage your Personal Information.
      </p>
      <h2>User Provided Information</h2>
      <p>
        The Website/Services/products records the information you provide when
        you register for the Services or products. When you register with us,
        you generally provide (a) your name, age, email phone number, password
        and your ward’s educational interests; (b) transaction-related
        information, such as when you make purchases, respond to any offers (c)
        information you provide us when you contact us for help; (d) information
        you enter into its system when using the Services/products, such as
        while asking doubts, participating in discussions and taking tests. The
        said information collected from the users could be categorized as
        “Personal Information“, “Sensitive Personal Information” and “Associated
        Information“. Personal Information, Sensitive Personal Information, and
        Associated Information (each as individually defined under this
        Information Technology (Reasonable security practices and procedures and
        sensitive personal data or information) Rules, 2011 (the “Data
        Protection Rules“)) shall collectively be referred to as “Information”
        in this Policy. SimplyGifted may use this information to contact you
        from time to time, to provide you with the Services, important
        information, required notices, and marketing promotions. SimplyGifted
        will request you when it needs more information that personally
        identifies you (personal information) or allows us to contact you.
        SimplyGifted will not differentiate between who is using the device to
        access the Website or Services or products, so long as the log in/access
        credentials match with yours. In order to make the best use of the
        Website/Services/products and enable your Information to be captured
        accurately on the Website/Services/products, it is essential that you
        have logged in using your own credentials. SimplyGifted will, at all
        times, provide the option to you, not to provide the Personal
        Information or Sensitive Personal Information, which it seeks from you.
        Further, you can, at any time while using the Services/products, also
        have an option to withdraw your consent given earlier to it, to use such
        Personal Information or Sensitive Personal Information. Withdrawal of
        the consent by you is required to be sent in writing to us at the
        contact details provided in this Policy below. In any such event,
        SimplyGifted or the Company fully reserves the right to withdraw further
        usage of the Website or provide any further Services/products thereunder
        to you.
      </p>
      <h2>Automatically Collected Information</h2>
      <p>
        In addition, the products/Services may collect certain information
        automatically, including, but not limited to, the type of mobile device
        you use, your mobile devices unique device ID, the IP address of your
        mobile device, your mobile operating system, the type of mobile Internet
        browsers you use, and information about the way you use the
        Services/products. SimplyGifted uses an outside credit card processing
        company to bill you for the goods and services availed by you. These
        companies do not retain, share, store or use personally identifiable
        information of you for any other purpose.
      </p>
      <h2>Use of your Personal Information</h2>
      <p>
        SimplyGifted uses the collected information to analyse trends, to
        conduct research, to administer the Services and products, to learn
        about each user’s learning patterns and movements around the Services
        and products and to gather demographic information and usage behavior
        about its user base as a whole. Aggregated and individual, anonymized
        and non-anonymized data may periodically be transmitted to external
        service providers to help us improve the Application, products and its
        Services. SimplyGifted will share your information with third parties
        only in the ways that are described below in this Policy. SimplyGifted
        may use the individual data and behavior patterns combined with personal
        information to provide you with personalized content and better your
        learning objectives/experience. Third parties provide certain services
        which we may use to analyze the data and information you provide to
        personalize, drive insights and help it better your experience or reach
        out to you with more value added applications, products, information and
        services. However, these third party companies do not have any
        independent right to share this information. SimplyGifted does not sell,
        trade or rent your Information to any third party unless, we have been
        expressly authorized by you either in writing or electronically to do
        so. SimplyGifted may at times provide aggregate statistics about its
        customers, traffic patterns, and related site information to reputable
        third parties, however this information when disclosed will be in an
        aggregate form and does not contain any of your Personally Identifiable
        Information. SimplyGifted will occasionally send email notices or
        contact you to communicate about its Services, products and benefits, as
        they are considered an essential part of the Services/products you have
        chosen.
      </p>
      <h2>SimplyGifted may disclose Information</h2>
      <p>
        As required by law, such as to comply with a subpoena, or similar legal
        process; to enforce applicable ToU, including investigation of potential
        violations thereof; when it believes in good faith (doctrine of uberrima
        fides) that the disclosure is necessary to protect its rights, protect
        your safety or the safety of others, investigate fraud, address security
        or technical issues or respond to a government request; with its trusted
        services providers who work on its behalf, do not have an independent
        use of the information SimplyGifted discloses to them, and have agreed
        to and adhered to the rules set forth in this Policy; to protect against
        imminent harm to the rights, property or safety of the Website/
        SimplyGifted or its users or the public as required or permitted by law;
        with third party service providers in order to personalize the
        Website/Services/products for a better user experience and to perform
        behavioural analysis; Any portion of the Information containing personal
        data relating to minors provided by you shall be deemed to be given with
        the consent of the minor’s legal guardian. Such consent is deemed to be
        provided by your registration with SimplyGifted.
      </p>
      <h2>Access to your Personal Information</h2>
      <p>
        SimplyGifted will provide you with the means to ensure that your
        Personal Information is correct and current. If you have filled out a
        user profile, it will provide an obvious way for you to access and
        change your profile from its Services/Website/products. SimplyGifted
        adopts stringent security measures to protect your password from being
        exposed or disclosed to anyone. Only upon forgetting the password for
        its Website or Services, SimplyGifted will have a right to reset the
        same to you with your prior approval as per standard web practices.
      </p>
      <h2>Cookies</h2>
      <p>
        SimplyGifted sends cookies (small files containing a string of
        characters) to your computer, thereby uniquely identifying your browser.
        Cookies are used to track your preferences, help you login faster, and
        aggregated to determine user trends. This data is used to improve its
        offerings, such as providing more content in areas of greater interest
        to a majority of users. Most browsers are initially set up to accept
        cookies, but you can reset your browser to refuse all cookies or to
        indicate when a cookie is being sent. Disclaimer: Some SimplyGifted
        features and services may not function properly if your cookies are
        disabled.
      </p>
      <h2>Links</h2>
      <p>
        SimplyGifted may present links in a format that enables us to keep track
        of whether these links have been followed. SimplyGifted uses this
        information to improve its customized content. Clicking on links may
        take you to sites outside its domain. SimplyGifted is not responsible
        for the privacy practices of other web sites. SimplyGifted encourages
        its users to be aware when they leave its site to read the EULA/T&Cs of
        each and every web site that collects personally identifiable
        information. This Privacy Policy applies solely to information collected
        by its Website.
      </p>
      <h2>Alerts</h2>
      <p>
        SimplyGifted may alert you by email or phone (through sms/call) to
        inform you about new service offerings or other information which it
        perceives might be useful for you.
      </p>
      <h2>Public Forums</h2>
      <p>
        When you use certain features on its website like the discussion forums
        and you post or share your personal information such as comments,
        messages, files, photos, will be available to all users, and will be in
        the public domain. All such sharing of information is done at your own
        risk. Please keep in mind that if you disclose personal information in
        your profile or when posting on its forums this information may become
        publicly available.
      </p>
      <h2>Security</h2>
      <p>
        SimplyGifted is concerned about safeguarding the confidentiality of your
        Information. SimplyGifted provides physical, electronic, and procedural
        safeguards to protect the Information it processes and maintains. An
        illustration being, that SimplyGifted limits access to this information
        to authorized employees only who need to know that information in order
        to operate, develop or improve its Services/products/Website. Please be
        aware that, although SimplyGifted’s endeavour is to provide stringent
        security, for information it possesses and maintains, no security system
        can prevent all potential security breaches.
      </p>
      <h2>How long does SimplyGifted retain user data?</h2>
      <p>
        Currently, SimplyGifted retains user data while an account is active and
        for at least five years afterward. It may alter this practice according
        to legal and business requirements. For example, it may lengthen the
        retention period for some data if needed to comply with law or voluntary
        codes of conduct. Unless otherwise prohibited, it may shorten the
        retention period for some types of data if needed to free up storage
        space.
      </p>
      <h2>Log information</h2>
      <p>
        When you access SimplyGifted Website, its servers automatically record
        information that your browser sends whenever you visit a website. These
        server logs may include information such as your web request, internet
        protocol address, browser type, browser language, the date and time of
        your request and one or more cookies that may uniquely identify your
        browser.
      </p>
      <h2>User communications</h2>
      <p>
        When you send an email or other communication to SimplyGifted, it may
        retain those communications in order to process your inquiries, respond
        to your requests and improve our Services.
      </p>
      <h2>Changes to this Statement</h2>
      <p>
        As the Company (SimplyGifted) evolves, its privacy policy will need to
        evolve as well to cover new situations. You are advised to review this
        Policy regularly for any changes, as continued use is deemed approval of
        all changes.
      </p>
      <h2>Your Consent</h2>
      <p>
        SimplyGifted believes that every user of our Services/Products/Website
        must be in a position to provide informed consent prior to providing any
        Information required for the use of the Services/Products/Website. By
        registering with it, you are expressly consenting to its collection,
        processing, storing, disclosing and handling of your information as set
        forth in this Policy now and as amended by us. Processing, your
        information in any way, including, but not limited to, collecting,
        storing, deleting, using, combining, sharing, transferring and
        disclosing information, all of which activities will take place in
        India. If you reside outside India your information will be transferred,
        processed and stored in accordance with the applicable data protection
        laws of India.
      </p>
      <h2>Contact Information</h2>
      <p>
        SimplyGifted Grievance Officer shall undertake all reasonable efforts to
        address your grievances at the earliest possible opportunity. You may
        contact it at: Reach out to us on outreach@simplygifted.us, in case of
        any queries.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
