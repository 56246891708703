import Sidenav from "../Reusables/SideNav";
import React from "react";
import { Button } from "@mui/material";
import { Logout } from "../Redux/action/User";
import { connect, useDispatch, useSelector } from "react-redux";
import store from "../Store/index";
import {ContentList} from "../Redux/action/User";
import { useEffect, useState } from "react";
// import Table from "antd";
import "antd/dist/antd.css";
// import { connect, useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
// import type { ColumnsType } from "antd/es/table";
const TestList = ({ ContentList }) => {

    

    // useEffect(() => {
    //   console.log("inside useEffect");

    //   ContentList((CALLBACK) => {
    //     console.log("call");
    //     if (!CALLBACK.exists) {
    //       console.log("CALLBACK", CALLBACK);
    //     }
    //   });
    // }, []);

    const TableColumns = [
        {
            title : "Title",
            dataIndex : "Title"  ,
        },

        {
            title : "Paragraph",
            dataIndex : "Paragraph1"  ,
        },

        {
         title : "Paragraph",
            dataIndex : "Paragraph2"   ,
        },

        // {
        //     title
        // }
    ]

    

  return (
    <div>
      Hello
      <Table
        // style={{ margin: 400 }}
        columns={TableColumns}
        dataIndex={ContentList}
        // visible={true}   
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ContentList: (data, callback) => dispatch(ContentList(data, callback)),
  //   showLoading: (callback) => dispatch(showLoading(callback)),
});

export default connect(null, mapDispatchToProps)(TestList);

