import React from "react";
import Banner from "../Components/AboutUs/Banner";
import WhereItAllBegan from "../Components/AboutUs/WhereItAllBegan";
import WelcomeToSG from "../Components/WelcomeToSG";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";
import HowItWorks from "../Components/HowItWorks";
import MeetTheTeam from "../Components/AboutUs/MeetTheTeam";

const AboutUs = () => {
  return (
    <>
      <HeaderTwo />
      <WelcomeToSG from="about" />
      <WhereItAllBegan />
      <MeetTheTeam />
      <HowItWorks from="about" />
      <Footer />
    </>
  );
};

export default AboutUs;
