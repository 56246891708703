import React from "react";
import "../styles/Banner.css";

const Banner = () => {
  return (
    <div className="containerBanner">
      <div className="bannerDiv">
        {/* <img src={Aeroplane}></img> */}
        <h3>Help Your Children Reach Their Potential With Our</h3>
        <h1>Test Prep for Gifted Programs</h1>

        <p>
          Preparing your child for tests to Gifted and Talented programs, one
          step at a time.
        </p>

        <div className="CTADiv">
          <a href="/contact">SCHEDULE A CALL NOW</a>
        </div>
      </div>
      {/* <div className="lineimg" style={{ position: "relative" }}>
        <img src={LineTop} style={{ width: "100vw" }} />
        <button
        >
          Schedule A Call
        </button>
      </div>

      <div style={{ position: "relative", bottom: 70 }}>
        <img src={LineTop} style={{ width: "100%" }} />
      </div> */}
    </div>
  );
};

export default Banner;
