import React from "react";
import WhatParentsSay from "../Components/WhatParentsSay";
import Statistics from "../Components/Statistics";
import Footer from "../Components/Reusable/Footer";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import HowItWorks from "../Components/HowItWorks";
import WEDoSomething from "../Components/WEDoSomething";
import WelcomeToSG from "../Components/WelcomeToSG";
import WhatSetsUsApart from "../Components/WhatSetsUsApart";
import ScheduleACall from "../Components/ScheduleACall";
import { Button } from "@mui/material";
import OurPopularTests from "../Components/OurPopularTests";
import Banner from "../Components/Banner";
// import WhatParentsSay from "../Components/WhatParentsSay";

const Home = () => {
  return (
    <div>
      <HeaderTwo />
      <Banner />
      <HowItWorks from="home" />
      <WEDoSomething />
      <WelcomeToSG from="home" />
      <WhatSetsUsApart />
      <OurPopularTests from="home" />
      <WhatParentsSay />
      <ScheduleACall />
      <Footer />
    </div>
  );
};

export default Home;
