import React from "react";
import Abhishek from "../../assets/Half Image.jpg";
import Kunjal from "../../assets/kunjal.jpg";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import "../../styles/MeetTheTeam.css";

const MeetTheTeam = () => {
  return (
    <div className="containerMTT">
      <div className="Font1MTT">
        <h1>Meet The Team</h1>
      </div>
      <div className="contentMTT">
        <div className="CardMTT">
          <Card>
            <CardMedia
              component="img"
              height="220"
              image={Abhishek}
              alt="image"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color="#FAAF11"
                fontFamily="Poppins"
                fontSize="1.4rem"
              >
                Abhishek Virginkar
              </Typography>
              <Divider />
              <Typography
                variant="body2"
                color="text.secondary"
                fontFamily="Poppins"
                fontSize="1rem"
              >
                Co-founder
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardMedia
              component="img"
              height="220"
              image={Kunjal}
              alt="image"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color="#FAAF11"
                fontFamily="Poppins"
                fontSize="1.5rem"
              >
                Kunjal Harshavat
              </Typography>
              <Divider />
              <Typography
                variant="body2"
                color="text.secondary"
                fontFamily="Poppins"
                fontSize="1rem"
              >
                Co-founder
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
