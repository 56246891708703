import logo from "./logo.svg";
import "./App.css";

import Card from "./Components/Reusable/Card";

import Home from "./Pages/Home.jsx";
import ContactUsPage from "./Pages/ContactUsPage.jsx";
import AboutUs from "./Pages/AboutUs.jsx";
import Tests from "./Pages/Tests.jsx";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import HeaderTwo from "./Components/Reusable/HeaderTwo";
import Footer from "./Components/Reusable/Footer";
import FileNotFound from "./Pages/FileNotFound.jsx";
import TestList from "./Components/AdminDashboard/List/list";
import AddTest from "./Components/AdminDashboard/Test/addTest";
import { Link } from "react-router-dom";
import Test from "./Pages/Test.jsx";
import Resources from "./Pages/Resources.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import TermsAndConditions from "./Pages/TermsAndConditions.jsx";
import AdminLogin from "./Components/AdminDashboard/Login/index";
import DashboardHome from "./Components/AdminDashboard/Dashboard/index";
import Index from "./Components/AdminDashboard/index";
import AddResource from "./Components/AdminDashboard/Resource/addResource";
import FetchResources from "./Components/AdminDashboard/Resource/resources";
import { useRef } from "react";
import Resource from "./Pages/Resource.jsx";

export default function App() {
  return (
    <>
      {/* <BrowserRouter> */}
      {/* <HeaderTwo /> */}
      <Router>
        <Routes>
          <Route exact path="/login" element={<AdminLogin />} />
          <Route exact path="/Dashboard" element={<DashboardHome />} />
          <Route exact path="/addtest" element={<AddTest />} />
          <Route exact path="/list" element={<TestList />} />
          <Route exact path="/admin" element={<Index />} />
          <Route exact path="/addtest" element={<AddTest />} />
          <Route exact path="/addupdate" element={<AddResource />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/tests" element={<Tests />} />
          <Route exact path="/tests/:id" element={<Test />} />
          <Route exact path="/resources" element={<FetchResources />} />
          <Route exact path="/contact" element={<ContactUsPage />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/updates" element={<Resources />} />
          <Route exact path="/updates/:id" element={<Resource />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/tnc" element={<TermsAndConditions />} />
          <Route exact path="/page-not-found" element={<FileNotFound />} />
          <Route path="*" element={<Navigate to="/page-not-found" replace />} />
          {/* <Home/>
<ContactUsPage/>
<AboutUs/>
<Tests/> */}
        </Routes>
      </Router>
      {/* </BrowserRouter> */}
    </>
  );
}

function Sidenav() {
  return (
    <nav>
      <Link to="/">Home</Link>
      <Link to="/dashboard">dashboard</Link>
    </nav>
  );
}
