import React, { useEffect, useState } from "react";
import Image from "../../assets/WelcomeToSG.png";
import Children from "../../assets/Children.jpeg";
import "../../styles/PopularTests.css";

const WhereItAllBegan = () => {
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    window.innerWidth > 600 ? setViewMore(true) : setViewMore(false);
  }, []);

  return (
    <div className="containerSG">
      <div className="Font1">
        <h1>Where it all began</h1>
      </div>
      <div className="content">
        <img src={Children} alt="Welcome" className="welcomeImg" />
        <div className="whereItBegan">
          <p>
            Much before the concept of SimplyGifted was born, my co-founder
            Kunjal and I had the experience of going through the entire process
            of getting our children, Aarush and Shourya into Gifted programs
            ourselves. Through our learnings during this time, we realised the
            importance of providing our children with tutoring that focuses on
            imparting quality education as per each child's needs.
          </p>
          {viewMore && (
            <>
              <p>
                In fact, it was the two young lads who suggested the idea of
                creating a platform, and urged us to start a venture that
                focuses on test prep for such Gifted & Talented programs, with
                the intention of giving personalised attention to every child.
              </p>
              <p>And so, we set out to create SimplyGifted!</p>
            </>
          )}
        </div>
        {/* <div className="Font1">
          <p>
           
          </p>

          {viewMore && (
            <>
              <p>
                In fact, it was the two young lads who suggested the idea of
                creating a platform, and urged us to start a venture that
                focuses on test prep for such Gifted & Talented programs, with
                the intention of giving personalised attention to every child.
              </p>
              <p>And so, we set out to create SimplyGifted!</p>
              <p>
                With the idea of providing personalized tutoring from teachers
                experienced in working with children, we’ve put together a
                network of quality tutors having over 10+ years of teaching
                experience, over the past two years, experimenting and working
                with children, parents and tutors, to design a valuable product.
              </p>
              <p>
                SimplyGifted doesn't attempt to go over the top with excessive
                features on offer. The idea is straightforward - Personalized.
                Affordable. Simple. Test content for entrances to Gifted
                programs can be easily available across the internet, as does
                our program. However, bombarding our children with test prep
                material is not going to really get us the best outcomes. That's
                why we focus on delivering value, mentoring and training as per
                your child's needs. Having gone through the experience as
                parents ourselves, we understand the importance of preparing
                one's child for various tests, and a good tutor can make a big
                difference in these cases. SimplyGifted has set out on a journey
                to deliver just that, and we look forward to having you with us
                as we build a great experience.
              </p>
              <div className="cofounder">
                <p>-Abhishek</p>
                <p>Co-founder at SimplyGifted</p>
              </div>
            </>
          )}
          <button
            className="viewMoreBtn"
            onClick={() => {
              setViewMore(!viewMore);
            }}
          >
            {viewMore ? "View Less" : "View More"}
          </button>
        </div> */}
      </div>
      {viewMore && (
        <div className="whereItBegan2">
          <p>
            With the idea of providing personalized tutoring from teachers
            experienced in working with children, we’ve put together a network
            of quality tutors having over 10+ years of teaching experience, over
            the past two years, experimenting and working with children, parents
            and tutors, to design a valuable product.
          </p>
          <p>
            SimplyGifted doesn't attempt to go over the top with excessive
            features on offer. The idea is straightforward - Personalized.
            Affordable. Simple. Test content for entrances to Gifted programs
            can be easily available across the internet, as does our program.
            However, bombarding our children with test prep material is not
            going to really get us the best outcomes. That's why we focus on
            delivering value, mentoring and training as per your child's needs.
            Having gone through the experience as parents ourselves, we
            understand the importance of preparing one's child for various
            tests, and a good tutor can make a big difference in these cases.
            SimplyGifted has set out on a journey to deliver just that, and we
            look forward to having you with us as we build a great experience.
          </p>
          <div className="cofounder">
            <p>-Abhishek</p>
            <p>Co-founder at SimplyGifted</p>
          </div>
        </div>
      )}
      <div className="viewMoreBtnDiv">
        <button
          className="viewMoreBtn"
          onClick={() => {
            setViewMore(!viewMore);
          }}
        >
          {viewMore ? "View Less" : "View More"}
        </button>
      </div>
    </div>
  );
};

export default WhereItAllBegan;
