import React from "react";
import "../styles/WhatSetsUsApart.css";
import Card from "@mui/material/Card";
import { CardContent, CardMedia, Typography } from "@mui/material";
import Affordable from "../assets/Affordable.png";
import Simple from "../assets/Simple icon.png";
import Personalized from "../assets/Personalized.png";
import Enjoyable from "../assets/Enjoyable.png";

const WhatSetsUsApart = () => {
  return (
    <div className="containerWSUA">
      <div className="headerWSUA">
        <h1>What sets us apart</h1>
      </div>
      <div>
        <p className="textWSUA">
          At SimplyGifted, we aspire to provide worthwhile content and
          remarkable tutoring whilst ensuring an enjoyable learning experience
          for your child. We resort to accomplish this with our philosophy of
          simplicity, affordability, and personalized learning.
        </p>
      </div>
      <div className="cardsWSUADiv">
        <Card className="cardWSUA">
          <CardMedia
            component="img"
            height="100"
            image={Simple}
            alt="CogAT"
            style={{ objectFit: "contain", padding: "1rem" }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="#FAAF11"
              fontFamily="Poppins"
            >
              Simple
            </Typography>
            <Typography variant="p" color="text.secondary" fontFamily="Poppins">
              Say goodbye to unwanted features & an explosion of unnecessary
              content. Here we keep things simple, yet functional.
            </Typography>
          </CardContent>
        </Card>
        <Card className="cardWSUA">
          <CardMedia
            component="img"
            height="100"
            image={Affordable}
            alt="CogAT"
            style={{ objectFit: "contain", padding: "1rem" }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="#FAAF11"
              fontFamily="Poppins"
            >
              Affordable
            </Typography>
            <Typography variant="p" color="text.secondary" fontFamily="Poppins">
              We understand that planning your finances for your child's
              education can be uneasy, and hence focus greatly on being
              reasonable.
            </Typography>
          </CardContent>
        </Card>
        <Card className="cardWSUA">
          <CardMedia
            component="img"
            height="100"
            image={Personalized}
            alt="CogAT"
            style={{ objectFit: "contain", padding: "1rem" }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="#FAAF11"
              fontFamily="Poppins"
            >
              Personalized
            </Typography>
            <Typography variant="p" color="text.secondary" fontFamily="Poppins">
              Nothing like deeper attention to the needs of every student. Our
              program will be tailored to assist your child’s individual
              learning style.
            </Typography>
          </CardContent>
        </Card>
        <Card className="cardWSUA">
          <CardMedia
            component="img"
            height="100"
            image={Enjoyable}
            alt="CogAT"
            style={{ objectFit: "contain", padding: "1rem" }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="#FAAF11"
              fontFamily="Poppins"
            >
              Enjoyable
            </Typography>
            <Typography variant="p" color="text.secondary" fontFamily="Poppins">
              What is an experience if not fun? Here's to making this journey
              delightful and worthy of a sense of satisfaction!
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default WhatSetsUsApart;
