import React from "react";

// import LogIn from "./login";
// import LogIn from "./login";
import { Router, Switch, Route, BrowserRouter, Routes } from "react-router-dom";
import Sidenav from "../Reusables/SideNav";
import Dashboard from "./dashboard";

const DashboardHome = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardHome;
