import axios from "axios";
import {
  REACT_APP_GATEWAY_URL,
  REACT_APP_AUTH,
  REACT_APP_VERSION,
} from "../Utils/utils";

export default axios.create({
  baseURL: REACT_APP_GATEWAY_URL,
  responseType: "json",
  headers: {
    Authorization: REACT_APP_AUTH,
    // "Content-Type": "application/json",
    AppVersion: REACT_APP_VERSION,
  },
});
