import React from "react";
import SingleTest from "../Components/SingleTest";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";

const Test = () => {
  return (
    <>
      <HeaderTwo />
      <SingleTest />
      <Footer />
    </>
  );
};

export default Test;
