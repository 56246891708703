import React from "react";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import axios from "axios";
import { ContentList } from "../Redux/action/User";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Table } from "antd";

const Home = ({ ContentList }) => {
  const [tests, setTests] = useState([]);

  useEffect(() => {
    ContentList((callback) => {
      console.log("Hello");
      if (callback.error === "undefined") {
        console.log("Something Went wrong");
      } else {
        if (callback.tests.length > 0) {
          setTests(callback.tests);
        }
      }
    });
  }, []);

  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },
    {
      title: "Paragraph1",
      dataIndex: "Paragraph1",
      key: "Paragraph1",
    },
    {
      title: "Paragraph2",
      dataIndex: "Paragraph2",
      key: "Paragraph2",
    },
    {
      title: "Added By",
      dataIndex: "AddedBy",
      key: "AddedBy",
    },
  ];

  return (
    <div>
      <Table dataSource={tests} columns={columns} />
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  const { AdminDetails } = user;
  return {
    AdminDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ContentList: (callback) => dispatch(ContentList(callback)),
  //   showLoading: (callback) => dispatch(showLoading(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
