import React from "react";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";
import Terms from "../Components/TermsAndConditions";

const TermsAndConditions = () => {
  return (
    <>
      <HeaderTwo />
      <Terms />
      <Footer />
    </>
  );
};

export default TermsAndConditions;
