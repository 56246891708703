import React from "react";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";
import Privacy from "../Components/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderTwo />
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
