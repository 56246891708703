import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, Router } from "react-router-dom";

import "../../App.css";

// import Card from "./Components/Reusable/Card";

import Home from "../../Pages/Home";
// import ContactUsPage from "./Pages/ContactUsPage";
// import AboutUs from "./Pages/AboutUs";
// import Tests from "./Pages/Tests";
import {
  BrowserRouter ,
  Routes,

  Navigate,
} from "react-router-dom";

import AdminLogin from "../AdminDashboard/Login/index";
import Lists from "./List/index";

import NewTest from "./Test/index";

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<AdminLogin />} />
        <Route exact path="/list" element={<Lists />} />
        <Route exact path="/AddTest" element={<NewTest />} />
      </Routes>
    </Router>
  );
}

export default Index;
