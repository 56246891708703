import React from "react";

// import LogIn from "./login";
// import LogIn from "./login";
import AddTest from "./addTest";
import { Router, Switch, Route, BrowserRouter, Routes } from "react-router-dom";

const NewTest = () => {
  return <AddTest />;
};

export default NewTest;
