import React, { useState } from "react";
import Image from "../assets/ScheduleACallKid.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import moment from "moment";
import "../styles/ScheduleACall.css";
import { useEffect, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";

const ScheduleACall = () => {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [grade, setGrade] = React.useState("Kindergarten");
  const [expectedDate, setExpectedDate] = React.useState(moment(new Date()));
  const [dob, setDob] = React.useState(moment(new Date()));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [childFirstName, setChildFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [CogAt, setCogAt] = useState(false);
  const [ITBS, setITBS] = useState(false);
  const [NNAT, setNNAT] = useState(false);
  const [OLSAT, setOLSAT] = useState(false);
  const [others, setOthers] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showMandatory, setShowMandatory] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setEmail(event.target);
      setError(null);
    }

    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      childFirstName === "" ||
      email === "" ||
      contact === ""
    ) {
      setShowMandatory(true);
      return;
    }

    let tests = `${CogAt ? "CogAT" : ""} ${ITBS ? "ITBS" : ""} ${
      NNAT ? "NNAT" : ""
    } ${OLSAT ? "OLSAT" : ""} ${others}`;

    const data = {
      firstName: childFirstName,
      surname: null,
      email: null,
      phone1: null,
      field1: moment(expectedDate).format("YYYY-MM-DD"),
      photo: null,
      nationality: null,
      dob: moment(dob).format("YYYY-MM-DD"),
      source: "external",
      parentPhone1: contact,
      address: {
        street: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      school: null,
      schoolYear: grade,
      curriculum: null,
      parentFirstName: firstName,
      parentSurname: lastName,
      relation: null,
      parentEmail: email,
      invoice: null,
      field2: tests,
      signature: null,
      gender: null,
      invoiceTo: null,
      isEmergency: null,
    };

    axios
      .post("https://api.classcardapp.com/api/v2/public/leads/add", data, {
        headers: {
          "data-type": "application/json",
          "api-key": "dd8fcba8-e4fe-4575-8acf-b7b38bc1623c",
        },
      })
      .then((response) => {
        setShowAlert(true);
        setFirstName("");
        setLastName("");
        setContact("");
        setChildFirstName("");
        setEmail("");
        setOthers("");
        console.log(response);
      })
      .catch((err) => {
        setShowErrorAlert(true);
        console.log(err);
      });
  };

  return (
    <>
      <div className="formHeader">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
          autoHideDuration={4000}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thank you, We'll be in touch with you shortly
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showErrorAlert}
          onClose={() => {
            setShowErrorAlert(false);
          }}
          autoHideDuration={4000}
        >
          <Alert
            onClose={() => {
              setShowErrorAlert(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Something Went Wrong
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showMandatory}
          onClose={() => {
            setShowMandatory(false);
          }}
          autoHideDuration={4000}
        >
          <Alert
            onClose={() => {
              setShowMandatory(false);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            All Fields are Mandatory
          </Alert>
        </Snackbar>
        <h1>Schedule a Call</h1>
        <p>
          Share the test goals for your child with us. We will discuss these
          requirements with our team and prepare an apt study plan for your
          child.
        </p>
      </div>
      <div className="containerSC">
        <div className="containerSCDiv">
          <img src={Image} className="containerSCImg" alt="boy" />
        </div>
        <div className="callForm">
          <FormControl className="formControlSC" required>
            <Stack spacing={2}>
              <div className="formTwoInputs">
                <TextField
                  id="outlined-basic"
                  label="Enter your first name"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <TextField
                  id="outlined-basic"
                  label="Enter your last name"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <Box>
                <div className="formThreeInputs">
                  <TextField
                    id="outlined-basic"
                    label="Enter your child's first name"
                    value={childFirstName}
                    onChange={(e) => setChildFirstName(e.target.value)}
                    required
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Your Child's Date of Birth"
                      inputFormat="MM/DD/YYYY"
                      value={dob}
                      onChange={(e) => {
                        setDob(e.$d);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <Stack
                    direction="row"
                    style={{ alignItems: "center" }}
                    spacing={2}
                  >
                    <Typography style={{ fontFamily: "Poppins" }}>
                      Grade
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={grade}
                      defaultValue="Kindergarten"
                      label="Grade"
                      onChange={(e) => setGrade(e.target.value)}
                      autoWidth
                    >
                      <MenuItem value={"Kindergarten"}>Kindergarten</MenuItem>
                      <MenuItem value={"1st"}>1st</MenuItem>
                      <MenuItem value={"2nd"}>2nd</MenuItem>
                      <MenuItem value={"3rd"}>3rd</MenuItem>
                      <MenuItem value={"4th"}>4th</MenuItem>
                      <MenuItem value={"5th"}>5th</MenuItem>
                      <MenuItem value={"6th"}>6th</MenuItem>
                      <MenuItem value={"7th"}>7th</MenuItem>
                      <MenuItem value={"8th"}>8th</MenuItem>
                    </Select>
                  </Stack>
                </div>
              </Box>
              <TextField
                id="outlined-basic"
                label="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                id="outlined-basic"
                label="Contact no."
                variant="outlined"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Expected Test Attempt Date"
                  inputFormat="MM/DD/YYYY"
                  value={expectedDate}
                  onChange={(e) => {
                    setExpectedDate(e.$d);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Typography style={{ fontFamily: "Poppins" }}>
                Tests Interested
              </Typography>
              <div className="checkBox">
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setCogAt(e.target.checked)} />
                  }
                  label="CogAT"
                  sx={{ fontFamily: "Poppins" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setITBS(e.target.checked)} />
                  }
                  label="ITBS"
                  style={{ fontFamily: "Poppins" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setNNAT(e.target.checked)} />
                  }
                  label="NNAT"
                  style={{ fontFamily: "Poppins" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setOLSAT(e.target.checked)} />
                  }
                  label="OLSAT"
                  style={{ fontFamily: "Poppins" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Others"
                  value={others}
                  onChange={(e) => {
                    setOthers(e.target.value);
                  }}
                />
              </div>
              {/* <TextField
                id="outlined-multiline-flexible"
                label="Tell us more (optional..)"
                multiline
                rows={5}
                // value={value}
                // onChange={handleChange}
              /> */}
            </Stack>
            <div className="scButton">
              <Button
                sx={{
                  width: "20%",
                  backgroundColor: "#faaf11",
                  marginTop: "1rem",
                }}
                variant="contained"
                type="submit"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </div>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default ScheduleACall;
