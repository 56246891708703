import React from "react";
import BackgroundImage from "../assets/FileNotFound.png";
import LineTop from "../assets/LineTop.png";
import "../styles/BannerPageNotFound.css";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";

const FileNotFound = () => {
  return (
    <>
      <HeaderTwo />
      <div className="containerPNF">
        <div className="bannerDivPNF">
          {/* <h3>A New Approach to</h3> */}
          <h1>Oops That Page is Gone </h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the
          </p>
          <div className="CTA">
            <a href="/">BACK TO HOMEPAGE</a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FileNotFound;
