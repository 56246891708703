import styles from "./sidenav.module.css";
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { navData } from "./SideNavData";
import { useState } from "react";

export default function Sidenav() {
  const [open, setopen] = useState(false);
  const toggleOpen = () => {
    setopen(!open);
    console.log("open", open);
  };
  return (
    // <div className={open ? styles.sidenav : styles.sidenavClosed}>
    <div className = { !open ? styles.sidenavClosed : styles.sidenav }>
      <button className={styles.menuBtn} onClick={toggleOpen}>
        {open ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </button>
      {navData.map((item) => {
        return (
          <NavLink key={item.id} className={styles.sideitem} to={item.link}>
            {item.icon}
            <span className={styles.linkText}>{item.text}</span>
          </NavLink>
        );
      })}
    </div>
  );
}
