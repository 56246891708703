import React from "react";

// import LogIn from "./login";
import LogIn from "./login";
import { Router, Switch, Route, BrowserRouter, Routes } from "react-router-dom";

const AdminLogin = () => {
  return(
    <LogIn />
  );
  
};

export default AdminLogin;
