import React from "react";
import Banner from "../Components/ContactUs/Banner";
import ScheduleACall from "../Components/ScheduleACall";
import Footer from "../Components/Reusable/Footer";
import HeaderTwo from "../Components/Reusable/HeaderTwo";

const ContactUsPage = () => {
  return (
    <>
      <HeaderTwo />
      <ScheduleACall />
      <Footer />
    </>
  );
};

export default ContactUsPage;
