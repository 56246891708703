import React from "react";
import { Box } from "@mui/material";
import Sidenav from "../Reusables/SideNav";
import { useEffect } from "react";
import axios from "axios";
import { FetchResources, DeleteResource } from "../Redux/action/User";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Table } from "antd";

const Resources = ({ FetchResources, DeleteResource }) => {
  const [resources, setResources] = useState([]);

  useEffect(() => {
    FetchResources((callback) => {
      console.log("Hello");
      if (callback.error === null) {
        setResources(callback.resources);
      }
      // if (callback.error === "undefined") {
      //   console.log("Something Went wrong");
      // } else {
      //   if (callback.tests.length > 0) {
      //     setTests(callback.tests);
      //   }
      // }
    });
  }, []);

  const deleteResource = (record) => {
    const data = { ResourceRef: record.ResourceRef };
    DeleteResource(data, (callback) => {
      if (callback.error === null) {
        alert("Resource Deleted Successfully");
        window.location.reload();
      } else {
        alert(callback.error.ErrorMessage);
      }
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "BlogDescription 1",
      dataIndex: "BlogDescription1",
      key: "BlogDescription1",
    },
    {
      title: "BlogDescription 2",
      dataIndex: "BlogDescription2",
      key: "BlogDescription2",
    },
    {
      title: "",
      key: "delete",
      dataIndex: "delete",
      render: (text, record) => (
        <button onClick={deleteResource.bind(null, record)}>Delete</button>
      ),
    },
  ];

  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 2">
        <Sidenav />
      </Box>
      <Box
        gridColumn="span 8"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Table dataSource={resources} columns={columns} />
        </div>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ user }) => {
  const { AdminDetails } = user;
  return {
    AdminDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  FetchResources: (callback) => dispatch(FetchResources(callback)),
  DeleteResource: (data, callback) => dispatch(DeleteResource(data, callback)),
  //   showLoading: (callback) => dispatch(showLoading(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
