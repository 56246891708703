import React from "react";
import OurPopularTests from "../Components/OurPopularTests";
import TestBanner from "../Components/TestBanner";
import HeaderTwo from "../Components/Reusable/HeaderTwo";
import Footer from "../Components/Reusable/Footer";

const Tests = () => {
  return (
    <>
      <HeaderTwo />
      <OurPopularTests from="tests" />
      <Footer />
    </>
  );
};

export default Tests;
