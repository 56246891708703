import { Divider } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/SingleBlog.css";
import image from "../assets/swa.jpg";

const SingleBlog = () => {
  const [blog, setBlog] = useState({});

  let location = useLocation();
  let blogRef = location.pathname.split("/")[2];

  const updates = location.state.resources;

  const blogRefs = updates?.map((update) => update.ResourceRef);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://simplygiftedbackend.com/api/resource/fetchresource?ResourceRef=${blogRef}`,
        {
          headers: {
            Authorization: "NGlhQVNVWEc2czpoS1dna3d1SW9v",
            AppVersion: "1.0.0",
          },
        }
      );
      setBlog(response.data.Details[0]);
    };
    fetchData();
  }, []);

  return (
    <div className="singleBlogBody">
      <div className="blogDescription">
        <h1>{blog.Title}</h1>
        <img src={blog.Image1Url} alt="blog" />
        <p>{blog.BlogDescription1}</p>
        <div className="blogTwoImages">
          <img src={blog.Image2Url} />
          <img src={blog.Image3Url} />
        </div>
        <p>{blog.BlogDescription2}</p>
        <p>{blog.Paragraph2}</p>
        <img src={blog.Image4Url} alt="test" />
      </div>
      <div className="popularResourceTab">
        <h2>Latest Updates</h2>
        <div className="popularResource">
          {updates.map((update, index) => {
            if (index < 5) {
              return (
                <div className="popularResourcesDiv">
                  <div className="popularResourceMedia">
                    {update.ImageUrl?.split("ImageUploads/")[1]?.split(
                      "."
                    )[1] === "mp4" ? (
                      <video className="popularResourceDivVideo" controls>
                        <source
                          src={update.Image1Url}
                          type="video/mp4"
                        ></source>
                      </video>
                    ) : (
                      <img
                        src={update.Image1Url}
                        className="popularResourceDivImage"
                        alt="resource"
                      />
                    )}
                    {/* <img
                      src={resource.ImageUrl}
                      className="popularSingleTestImage"
                      alt="resource"
                    /> */}
                  </div>
                  <div className="popularSingleTestDivText">
                    <h3>{update.Title}</h3>
                    <Divider style={{ backgroundColor: "#faaf11" }} />
                    <p>
                      {moment(update.CreatedOn.split(" ")[0]).format(
                        "MMMM Do, YYYY"
                      )}
                    </p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
