import HomeIcon from "@mui/icons-material/Home";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import Home from "../Components/Home";
export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Home",
    link: "/Dashboard",
  },
  {
    id: 1,
    icon: <AddIcon />,
    text: "Add Test",
    link: "/addtest",
  },
  {
    id: 2,
    icon: <TravelExploreIcon />,
    text: "Add Update",
    link: "/addupdate",
  },
  {
    id: 3,
    icon: <BarChartIcon />,
    text: "Resources",
    link: "/resources",
  },
];
