import React from "react";
// import Carousel from "framer-motion-carousel";
import Carousel from "react-material-ui-carousel";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import WhatParentsSayImage from "../assets/WhatParentsSay_2.png";
import "../styles/WhatParentsSay.css";

const WhatParentsSay = () => {
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      PrevIcon={<AiOutlineLeft size={50} />}
      NextIcon={<AiOutlineRight size={50} />}
      navButtonsProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
      autoPlay={true}
      animation="slide"
      indicators={false}
    >
      <div className="whatParentsDiv">
        <h2>What Parents Say</h2>
        <h3>
          “I was looking out for some good quality teachers to prepare my child
          for Gifted Programs when I came across SimplyGifted. Their team truly
          helped in improving my child’s scores in the CogAT and prepare him for
          the actual test”
        </h3>
        <h4 style={{ fontWeight: "500", margin: 0, marginTop: "1rem" }}>
          Nidhi Thakur
        </h4>
        <h4
          style={{
            fontWeight: "300",
            fontSize: "1.2rem",
            margin: 0,
            padding: 0,
          }}
        >
          Parent
        </h4>
      </div>

      <div className="whatParentsDiv">
        <h2>What Parents Say</h2>
        <h3>
          "Nice! No clutter or unnecessary features, just straight, simple
          tutoring sessions & tests"
        </h3>
        <h4 style={{ fontWeight: "500", margin: 0, marginTop: "1rem" }}>
          Sejal Shah
        </h4>
        <h4
          style={{
            fontWeight: "300",
            fontSize: "1.2rem",
            margin: 0,
            padding: 0,
          }}
        >
          Parent
        </h4>
      </div>

      <div className="whatParentsDiv">
        <h2>What Parents Say</h2>
        <h3>
          “We tried SimplyGifted and it turned out to be wonderful! The teaching
          was really nice and our daughter was really engrossed in learning.”
        </h3>
        <h4 style={{ fontWeight: "500", margin: 0, marginTop: "1rem" }}>
          Tejasi Govande
        </h4>
        <h4
          style={{
            fontWeight: "300",
            fontSize: "1.2rem",
            margin: 0,
            padding: 0,
          }}
        >
          Parent
        </h4>
      </div>

      {/* <div style={{ backgroundColor: "#00A3E3" }}>
        <img
          src={WhatParentsSayImage}
          style={{
            backgroundRepeat: "no-repeat",
            objectFit: "contain",
            width: "100%",
            alignSelf: "flex-end",
            justifySelf: "flex-end",
          }}
        />
      </div> */}
    </Carousel>
  );
};

export default WhatParentsSay;
