import React from "react";
import "../styles/TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="termsAndCondBody">
      <h1>Terms & Conditions</h1>
      <p>
        These Terms and Conditions of Online Courses, which incorporate by
        reference the Terms and Conditions of Website Use and which are subject
        to any Additional Online Terms and Conditions (if any) (together, the
        “Online Terms”), apply to the sale of any Online Course. Please read the
        Online Terms carefully before purchasing an Online Course and print off
        a copy for your records. By ordering an Online Course, you are
        confirming your agreement to be bound by the Online Terms.
      </p>
      <h2>Online Course Provider</h2>
      <p>
        The Online Courses are provided by SimplyGifted (hereinafter referred to
        as “SimplyGifted”, “we” or “us”), a brand of Global Guru LLC Naperville,
        IL. www.simplygifted.us is operated by SimplyGifted. We will use our
        reasonable endeavors to provide the Online Courses advertised by
        SimplyGifted. We will provide Online Courses using reasonable care and
        skill. We may appoint independent sub-contactors to assist in providing
        Online Courses or use third parties to arrange or supply certain aspects
        of, or services in connection with, our Online Courses. You agree that
        our obligation to you is to use reasonable care in selecting competent,
        independent Subcontractors and third-party suppliers to provide
        reasonable services related to the Online Courses. You agree that
        SimplyGifted is not responsible for the actions or omissions of such
        Subcontractors or third party suppliers
      </p>
      <h2>Definitions</h2>
      <p>
        Capitalised terms used herein (and not otherwise defined) have the
        following meaning: “Additional Study Materials” means any Online Study
        Materials or Physical Study Materials not included in the Course Fee.
        “Additional Online Terms and Conditions” means any additional terms and
        conditions relating to an Online Course agreed in writing by
        SimplyGifted with a particular student; “Course Fee” means the fee
        payable for an Online Course but excludes any delivery charges payable
        in relation to the delivery of Course Materials, if applicable, and any
        import duties, taxes and customs clearances which may be payable;
        “Course Materials” means the materials provided by SimplyGifted in the
        course of the delivery of any Online Course, which may be (i) Online
        Study Materials and/or (ii) Physical Study Materials; “Online Course”
        means either an On Demand Online Course or a Scheduled Online Course
        provided by Unbound; “Online Study Materials” means any material in an
        electronic format which may be (i) downloaded from the Website or (ii)
        accessed and viewed on the Website, including, but not limited to, PDFs,
        MP3s, JPEGs, MPEG4s and MOVs; “Physical Study Materials” means any
        material in a physical format, including, but not limited to, DVDs, CD
        Rom, Blu-ray, and Audio CDs; “Scheduled Online Course means an online
        course which is only available for access by you on a predetermined
        start date; “Terms and Conditions of Website Use” means the terms and
        conditions pursuant to which you may access the website. “Website” means
        www.simplygifted.us or any other domain operated by SimplyGifted; and
        “you” means the individual purchasing the Online Course. The use of
        singular words herein also includes the plural.
      </p>
      <h2>Purchasing and Confirmation</h2>
      <p>
        In order to purchase an Online Course you must provide the required
        information and pay the amount specified. You must ensure that all
        information provided is complete and accurate. When you place an order
        for an Online Course, you are offering to purchase that Online Course on
        the Online Terms. A legally binding agreement shall only come into
        existence once: (a) SimplyGifted has accepted your offer to purchase an
        Online Course by sending you an order confirmation email which will
        provide you with a link to access the Online Course, along with log on
        details (if applicable); and (b) SimplyGifted has received from you (or
        on your behalf) the entire Course Fee in cleared funds. The Online
        Terms, together with your purchase offer and the order confirmation
        email, constitute the entire agreement between us (the “Contract”) and
        supersedes and replaces any other terms and conditions previously
        published by us and any other understanding, undertaking,
        representation, warranty, arrangement, promise or statement of any
        nature whatsoever made by us to you, whether oral, written or otherwise,
        relating to the subject matter of the Online Terms. You acknowledge that
        you have not relied on any understanding, undertaking, representation,
        warranty, arrangement, promise or statement made or given by us or on
        our behalf, which is not set out in the Contract. You must check the
        details on the order confirmation email when you receive it. If there
        are any errors please contact us immediately at
        outreach@simplygifted.us. We reserve the right, in our sole and absolute
        discretion and without the need to give a reason, to refuse to accept an
        offer to purchase one or more Online Courses. In such circumstances, no
        contract will arise and we will return any payment accompanying your
        purchase offer. Before a Contract comes into existence between you and
        us, we reserve the right to increase or decrease the advertised Course
        Fee for an Online Course and/or to amend any of the information
        contained in the Online Course description.
      </p>
      <h2>Payment and Refund</h2>
      <p>
        The Course Fee for any Online Course at any given time and any
        additional delivery charges payable in relation to the delivery of
        Course Materials, if applicable, will be displayed on the website or
        communicated by Customer Relations team. All amounts are payable in US
        Dollars. Any currency conversion costs or other charges incurred in
        making a payment shall be borne by you, and shall not be deductible from
        the amounts due to SimplyGifted. The Course Fee is payable with a
        one-off lump-sum payment, payable with your purchase offer. The payments
        will be accepted by: (payment methods to be specified) (i) PayPal; (ii)
        credit card (including Visa, MasterCard and American Express) or debit
        card (including Visa Electron, Delta and Maestro); or (iii) bank
        transfer, which will need to be arranged directly with us over the
        telephone or by e-mail at outreach@simplygifted.us.
      </p>
      <h2>User Consent</h2>
      <p>
        By enrolling in the any of Paid or free program of the company (
        including trial lessons) , you allow company to record videos/ capture
        images primarily for quality checks and use in promotion of the
        services. The Company retains all the rights in the video recordings,
        sound/audio recordings, images, photos, pictures/ images clicked during
        the classes, lectures delivered by the Company’s mentors , text and
        other material posted on the Platform and shall be the sole owner of the
        same To check privacy policy please visit :
        https://www.simplygifted.us/privacy-policy
      </p>
    </div>
  );
};

export default TermsAndConditions;
